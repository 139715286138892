body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dm3-container {
  border-radius: 25px;  /* Optional property */
  overflow: hidden;  /* Optional property only if wanted set border radius */
  height: 100vh; /* If the container has no height, then it is mandatory to set some height*/
  width: 100vw; /* If the container has no width, then it is mandatory to set some width */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
